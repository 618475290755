@import "atoms/variables";@import "functions";
.headerContainer {
  position: relative;

  .header {
    align-items: center;
    background: $gradient-blue-primary-reverse;
    display: flex;
    justify-content: space-between;
    padding-bottom: rem(6px);
    padding-left: rem(18px);
    padding-right: rem(21px);
    padding-top: rem(6px);

    p {
      align-items: center;
      color: $white;
      display: flex;
      font-size: rem(15px);
      font-weight: 500;
    }

    .logoAndTitle {
      align-items: center;
      display: flex;

      .logo {
        height: rem(38px);
        margin-right: rem(8px);
        position: relative;
        width: rem(97px);
      }

      p {
        &::before {
          background-color: $semantic-warning;
          content: '';
          display: block;
          height: rem(40px);
          margin-right: rem(8px);
          width: rem(2px);
        }
      }
    }

    .logoutAndMobileMenu {
      & > button {
        display: flex;

        @media (min-width: $mobile-width) {
          display: none;
        }

        svg {
          color: $white;
        }
      }

      .desktopLogout {
        align-items: center;
        display: none;

        @media (min-width: $mobile-width) {
          display: flex;
        }

        p {
          margin-right: rem(9px);

          &::after {
            background-color: $white;
            content: '';
            display: block;
            height: rem(20px);
            margin-left: rem(9px);
            width: rem(2px);
          }
        }

        button {
          background-color: transparent;
          border: none;
          color: $white;
          font-size: rem(15px);
          font-weight: 500;
          padding: 0;
          text-decoration: underline;
        }
      }
    }
  }
}
