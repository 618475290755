@import "atoms/variables";@import "functions";
.signOut {
    height: rem(50px);
    width: 100%;
    background: none;
    border: none;
    padding: 0.5rem;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    &:hover {
        background-color: #233e7f !important;
        
        p {
            
            color: #ffffff !important;
            text-decoration: underline;
        }
    }

    p {
        font-weight: 400 !important;
        color: #515162 !important;
       
    }
}
