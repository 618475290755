@import "atoms/variables";@import "functions";

 
  
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    padding-left: 0;
    width: calc(950px + 1rem);

  }

  .suggestionsEvent {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    
    padding-left: 0;
    width: calc(360px + 1rem);

  }
  
  .suggestions li {
    padding: 0.5rem;
    color: white;
    display: flex;
    justify-content: space-between;
  }

  .suggestionsEvent li {
    padding: 0.5rem;
    color: black;
    display: flex;
    justify-content: space-between;
  }
  
  .suggestionActive,
  .suggestions li:hover {
    background-color: gray;
    color: black;
    cursor: pointer;
    font-weight: 700;
  }

  .suggestionActiveEvent,
  .suggestionsEvent li:hover {
    background-color: gray;
    color: black;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }

  .suggestionsEvent li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }