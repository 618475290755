@import "atoms/variables";@import "functions";
.modalContainer {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.box {
  background-color: $white;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: rem(10px);
  width: max-content;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);  

  .closeButton {
    position: absolute;
    right: rem(18px);
    top: rem(18px);

    svg {
      color: $primary-blue;
    }
  }

  .modalContent {
    padding: rem(18px);
    h2 {
      text-align: center;
    }
  }

  .title {
    color: $grey-dark-1;
    font-size: rem(25px);
    margin-bottom: rem(16px);
    margin-top: rem(18px);
    text-align: center;
  }
}
