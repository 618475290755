@import "atoms/variables";@import "functions";
.notificationBox {
  align-items: center;
  background-color: $gray-scale-50;
  border: rem(1px) solid $primary-green;
  border-radius: rem(10px);
  display: flex;
  padding: rem(10px) rem(20px);

  svg {
    color: $semantic-success;
    margin-right: rem(11px);
  }

  p {
    color: $green-800;
    font-weight: 500;
    margin: 0;
    white-space: nowrap;
  }
}
