@import "atoms/variables";@import "functions";
.headerBasic {
  align-items: center;
  background-color: $white;
  display: flex;
  height: rem(50px);
  justify-content: space-between;
  padding-left: rem(16px);
  padding-right: rem(26px);

  @media (min-width: $mobile-width) {
    height: rem(60px);
    padding-left: rem(25px);
    padding-right: rem(30px);
  }

  .logoContainer {
    height: rem(38px);
    position: relative;
    width: rem(98px);

    @media (min-width: $mobile-width) {
      height: rem(50px);
      width: rem(132px);
    }
  }
}
