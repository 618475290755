@import "atoms/variables";@import "functions";
.inputContainer {
  display: flex;
  flex-direction: column;
  margin: rem(8px) 0;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  label {
    font-size: rem(18px);
    font-weight: 500;
    margin-bottom: rem(8px);
    position: relative;
  }

  .description {
    margin-bottom: rem(8px);
  }

  & > p {
    margin-bottom: rem(13px);
  }

  & > div {
    position: relative;

    & > button,
    & > svg {
      position: absolute;
      right: rem(12px);
      top: 50%;
      transform: translateY(-50%);
    }

    svg {
      color: $gray-scale-600;
    }

    button {
      background-color: transparent;
      border: none;
      color: $primary-blue;
      cursor: pointer;
      padding: 0;

      &:focus {
        outline: none;
      }
    }

    &.grid {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-row-gap: rem(40px);
    }

    .characterCount {
      bottom: rem(6px);
      color: $gray-scale-800;
      font-size: rem(13px);
      position: absolute;
      right: rem(6px);
    }
  }

  &.error {
    input {
      border-color: $semantic-error;
    }

    & > p {
      color: $semantic-error !important;
      margin: rem(7px) 0 0 !important;
    }
  }
}

.inputnumber {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    width: 45px;
    height: 52px;
    line-height: 1.65;
    float: left;

    padding: 0;
    margin: 0;
    padding-left: 5px;
    border: 0px solid #eee;
    text-align: center;
  }
  :disabled {
    opacity: 0.3;
    cursor: not-allowed !important;
  }
  button {
    width: 95px;
    height: 55px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    font-size: 30px;
    border: 1px solid #eee;
  }
  button:disabled {
    opacity: 0.3;
    cursor: not-allowed !important;
  }
  .divcontainer {
    display: flex;
    border: 1px solid #eee;
    min-width: 170px;
    max-height: 55px;
    justify-content: space-around;
  }
}
