@import "atoms/variables";@import "functions";
.languageSelection {
  display: none;

  @media (min-width: $mobile-width) {
    display: flex;
  }

  button {
    background-color: transparent;
    border: none;
    color: $white;
    font-size: rem(14px);
    padding: 0;

    &.active {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  span {
    background-color: $yellow-400;
    height: rem(32px);
    margin: 0 rem(10px);
    width: rem(1px);
  }
}