@import "atoms/variables";@import "functions";
.dropdowncontainer{
    position: relative;
}

.dropdown {
    position: absolute;
    left: -6rem;
    top: calc(100% + .25rem);
    width: 300px;
    transform: translate(-45%);
    background-color: white;
    border: 1px solid #d3d4dd !important;
    padding: 1rem;
    overflow: hidden;
}
.dropdownMenuItem {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    color: #515162;
    &:hover {
        background-color: #233e7f !important;
        color: #ffffff;
    }

    img{
        margin-right : rem(8px);
    }
}

.iconButtonRight {
    margin-left: auto;
}

.iconStyle{
    margin-right: rem(8px);
}


