@import "atoms/variables";@import "functions";
.footer {
  .associateLogos {
    align-items: center;
    background-color: $gray-scale-100;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    gap: 20px;

    @media (max-width: 930px) {
      display: flex;
    }
  }

  .middleSection {
    background: $white;
    display: flex;
    justify-content: space-around;
    padding-bottom: rem(45px);
    padding-top: rem(45px);

    @media (max-width: $mobile-width) {
      align-items: center;
      background-color: $blue-primary-800;
      flex-direction: column;
      padding-top: rem(25px);
    }

    .logoContainer {
      height: rem(220px);
      position: relative;
      width: rem(180px);

      @media (max-width: $mobile-width) {
        height: rem(65px);
        margin-bottom: rem(33px);
        width: rem(170px);
      }

      & > div:first-of-type {
        @media (max-width: $mobile-width) {
          display: none !important;
        }
      }

      & > div:last-of-type {
        display: none !important;

        @media (max-width: $mobile-width) {
          display: block !important;
        }
      }
    }

    .links {
      display: inline-flex;
      justify-content: space-around;
      @media (max-width: $mobile-width) {
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      div > ul {
        padding: 0;

        li {
          list-style: none;
        }
      }

      & > div > ul:first-of-type {
        margin: 0 0 rem(32px);

        li {
          @media (max-width: $mobile-width) {
            text-align: center;
          }

          &:not(:last-child) {
            margin-bottom: rem(12px);

            @media (max-width: $mobile-width) {
              margin-bottom: rem(16px);
            }
          }

          a {
            color: $semantic-links;
            text-decoration: none;
            font-weight: 600;

            @media (max-width: $mobile-width) {
              color: $white;
            }
          }
        }
      }
      
      .firstDiv {
        & >  ul:last-of-type {
          display: flex;
          margin: rem(32px) 0 0;
  
          @media (max-width: $mobile-width) {
            justify-content: space-around;
            margin-bottom: rem(35px);
            max-width: rem(360px);
            width: 100%;
          }
  
          li a {
            font-size: 0;
          }
  
          .facebook a {
            color: $facebook-color;
          }
  
          .youtube a {
            color: $youtube-color;
          }
  
          .instagram a {
            color: $instagram-color;
          }
  
          .twitter a {
            color: $twitter-color;
          }
  
          .facebook a,
          .youtube a,
          .instagram a,
          .twitter a {
            @media (max-width: $mobile-width) {
              color: $white;
            }
          }
  
          li:not(:last-child) {
            margin-right: rem(25px);
  
            @media (max-width: $mobile-width) {
              margin: 0;
            }
          }
        }
      }
      
    }

    

    .appLinks {
      max-width: rem(150px);
      width: 100%;

      @media (max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: rem(360px);
      }

      p {
        color: $semantic-links;
        font-weight: 600;
        margin: 0 0 rem(17px);

        @media (max-width: $mobile-width) {
          color: $white;
          margin-bottom: rem(25px);
          text-align: center;
        }
      }

      .images {
        @media (max-width: $mobile-width) {
          display: flex;
          justify-content: space-around;
        }

        a {
          display: block;

          &:first-of-type {
            margin-bottom: rem(14px);
          }
        }
      }
    }
  }

  .footerEnd {
    background: $gradient-green-secondary;
    padding: rem(8px) 0;

    p {
      color: $gray-scale-900;
      font-size: rem(14px);
      margin: 0;
      text-align: center;
    }
  }
}
