@import "atoms/variables";@import "functions";
.loginBoxComponent {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  height: 100vh;
  top: 0;
  width: 100%;
  z-index: 9;
}
.content {
  width: 100%;
  padding: 8%;
}

.login-box {
}

.box {
  background-color: $white;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: rem(10px);
  max-width: rem(490px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  .logo {
    height: rem(65px);
    position: relative;
    width: rem(154.46px);
    top: rem(15px);
    left: rem(20px);
  }

  .iconOnly {
    position: absolute;
    right: rem(17px);
    top: rem(17px);
  }

  .registerAndLogin {
    h1 {
      font-size: rem(28px);
      margin-bottom: rem(20px);
      margin-top: rem(25px);
      text-align: center;
    }

    h1,
    p {
      align-self: center;
      justify-self: center;
    }

    .emailForm {
      .formContent {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: rem(332px);

        .inputContainer {
          margin-bottom: rem(15px);
          width: 100%;
        }

        .forgotPasswordButton {
          background-color: $white;
          border: none;
          color: $cyan-600;
          cursor: pointer;
          margin-bottom: rem(20px);
          text-decoration: underline;
        }

        .loginButton {
          max-width: rem(240px);
          margin: rem(15px) 0;
          padding: rem(10px) 0;
          width: 100%;
        }

        .errorMessage {
          color: $semantic-error;
        }

        & > p {
          margin: rem(7px) 0;

          &:last-child {
            margin-bottom: rem(25px);
          }
        }
      }

      & > p {
        border-top: 1px solid $gray-scale-300;
        margin: 0;
        padding: rem(28px) 0 rem(22px);
        text-align: center;
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: rem(390px);
      min-height: rem(340px);

      & > a,
      & > button {
        padding-top: rem(17px);
        padding-bottom: rem(17px);
      }

      & > a {
        margin-bottom: rem(15px);
        margin-top: rem(15px);
      }

      & > button {
        margin: rem(12px) 0;
      }

      .division {
        margin: rem(25px) 0;
        position: relative;
        text-align: center;

        &::before,
        &::after {
          background-color: $gray-scale-200;
          content: '';
          display: block;
          height: rem(1px);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 40%;
        }

        &::before {
          left: 0;
        }

        &:after {
          right: 0;
        }
      }
    }
  }

  .recoverPassword {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: rem(325px);

    h4 {
      font-size: rem(22px);
      margin-top: rem(45px);
    }

    .emailForPasswordRecoveryForm,
    .emailSent {
      & > p {
        margin: rem(16px) 0;
        font-weight: 400;
      }
    }

    .emailForPasswordRecoveryForm {
      .emailField {
        margin: rem(7px);
        width: 100%;
      }

      .buttons {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        .buttonNext:disabled {
          background: #f0f0f5;
          color: #696979;
          border: 1px solid #f0f0f5;
        }
        button:first-child {
          margin-bottom: rem(25px);
          margin-top: rem(40px);
          max-width: rem(240px);
          padding: rem(10px) 0;
          width: 100%;
        }

        button:last-child {
          background-color: $white;
          border: none;
          color: $cyan-600;
          margin-bottom: rem(50px);
          text-decoration: underline;
        }
      }
    }

    .emailSent {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-bottom: rem(30px);

      & > p {
        margin-bottom: rem(25px);
      }

      .buttonNext:disabled {
        background: #f0f0f5;
        color: #696979;
        border: 1px solid #f0f0f5;
      }

      .inputContainer {
        margin-bottom: rem(20px);
        width: rem(250px);
      }

      & > button {
        margin-top: rem(10px);
        padding-top: rem(15px);
        padding-bottom: rem(15px);
      }

      .passwordChangedMessage {
        align-items: center;
        display: flex;
        flex-direction: column;

        div {
          width: rem(450px);
        }

        button {
          margin-top: rem(20px);
        }
      }
    }
  }
}

.contenTerms {
  height: 400px;
  width: 100%;
  padding: 8%;
  overflow-y: scroll;
}
