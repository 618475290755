@import "atoms/variables";@import "functions";
.notification {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 4px;
  background: transparent;
  border: 1px solid white;
  border-radius: 5px;
  margin: 0px 8px;

  .textResponsive {
   
    font-family: Quicksand;
    font-size: 15px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: -0.0025em;
    text-align: center;

    @media (max-width: $mobile-width) {
      font-size: 0;
    }
  }

  & > a {
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    color: #ffffff;
    margin: 0px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  img {
    margin: 0 8px;
  }
}

.dr_menu {
  position: fixed;
  top: 48px;
  right: 10%;
  width: 380px;
  background: #FFFFFF;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.0025em;
  color: #515162;
  @media (max-width: $mobile-width) {
    top: 60px;
    right: 1%;
  }
  & > .titulo {
    background: #F7F7F7;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid #D3D4DD;
    font-size: 14px;
    line-height: 18px;
  }

  & > .contenido {


    & > ul {
      list-style: none;
      margin: 0;
      padding: 0;

      & .disable {
        background: #F0F0F5;
        color: #696979;

        & > a {
          & > div {
            & > div {
              & > span {
                color: #696979;
              }
            }
            & > span {
              color: #696979;
            }
          }
        }
      }

      & > li {
        padding: 10px 10px;
        border: 1px solid #D3D4DD;

        & > a {

          &:hover {
            text-decoration: none;
          }

          & > div {
            & > div {
              display: flex;
              align-items: center;
              & > img {
                margin-left: 0;
              }
              & > span {
                font-size: 15px;
                line-height: 19px;
              }
            }
            & > span {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: #24252E;
            }
          }
        }
      }
    }
  }

}
