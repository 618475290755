@import "atoms/variables";@import "functions";
.tooltip {
  display: flex;
  position: relative;

  &:hover .box {
    display: block;
  }

  svg {
    color: $primary-blue;
  }

  .box {
    background-color: $white;
    border: 1px solid $primary-blue;
    border-radius: rem(5px);
    display: none;
    max-width: rem(260px);
    padding-bottom: rem(11px);
    padding-left: rem(18px);
    padding-right: rem(7px);
    padding-top: rem(11px);
    position: absolute;
    right: rem(-150px);
    top: rem(35px);
    width: max-content;
    z-index: 2;

    @media (min-width: $mobile-width) {
      right: rem(-15px);
    }

    &::before,
    &::after {
      background-color: $white;
      content: '';
      position: absolute;
    }

    &::before {
      border: 1px solid $primary-blue;
      height: rem(15px);
      right: rem(15px);
      top: rem(-8px);
      transform: rotate(45deg);
      width: rem(15px);
    }

    &::after {
      height: rem(13px);
      right: rem(11px);
      top: 0px;
      width: 24px;
    }

    p {
      color: $primary-blue;
      font-size: rem(14px);
      font-weight: 500;
    }
  }
}
