@import "atoms/variables";@import "functions";
.headerm > div {
  display: flex;
  background-color: #ffffff;

  @media (max-width: $mobile-width) {
    flex-direction: row !important;
  }
}
.displayNone {

  @media (max-width: $mobile-width) {
    display: none !important;
  }
}
.headerm > div > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerm > div > div:last-child > div:first-child {
  background-image: linear-gradient(90deg, #3f69ca 0%, #233e7f 100%);
  display: flex;
  justify-content: space-around;

  @media (max-width: $mobile-width) {
    background: #ffffff;
  }
}
.headerm > div > div:last-child > div:first-child p {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
.headerm > div > div:last-child > div:last-child {
  display: flex;
  align-items: center;
}
.logo {
  height: 75px;
}
.headerx > div {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding: 5px 25px;
}
.headerx > div > div:last-child {
  display: flex;
  align-items: center;
}
.headerx a {
  display: inline-block;
}
.headerx img {
  width: 132px;
  height: 50px;
}
.logo img {
  width: 100%;
}
.logo a {
  display: block;
}
.logo {
  flex: 0 0 20%;
}
.menu_bar {
  flex: 0 0 80%;
}
.menu_bar_up {
  background: linear-gradient(90deg, #3f69ca 0%, #233e7f 100%);
  height: 48px;
  border-bottom-left-radius: 100px;
  padding: 8px 16px 8px 32px;
}

.textResponsive {
  @media (max-width: $mobile-width) {
    font-size: 0;
    display: none !important;
  }
}

.menu_bar_up a {
  color: #ffffff;
  font-family: Quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.0025em;
  text-decoration: none;
}
.menu a {
  color: #3a3a47 !important;
}
.menu_down_inner {
  padding: 0px 50px;
}
.menu a img {
  color: #3a3a47 !important;
  padding-right: 4px;
}

.menu_bar_up_a1 {
  padding: 6px 5px;
  border-right: 2px solid gray;
}
.menu_bar_up_a2 {
  padding: 6px 5px;
}

.dr_menu {
  position: relative;
}

.menu {
  position: absolute;
  margin-left: -102px;
  border: 1px solid #d3d4dd;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;

  @media (max-width: $mobile-width) {
    margin-left: 0 !important;
    position: fixed !important;
    top: 8rem !important;
    left: 1rem !important;
  }
}

.menu li {
  list-style: none;
}

.menu ul {
  margin: 0px;
  padding: 0px;
}

@media only screen and (max-width: 1095px) {
  .menu_bar_up {
    padding: 8px 16px 8px 23px;
  }
  .menu_bar_up_in {
    padding: 0px !important;
  }
  .menu_down_inner {
    padding: 0px 15px;
  }
  .menu a {
    color: #3a3a47 !important;
    font-size: 16px;
  }
}
@media only screen and (max-width: 992px) {
  .menu_bar_up a {
    font-size: 12px;
  }
  .menu_bar_up_in2 {
    padding: 0 !important;
  }


  .menu_bar_up_in2 {
    padding: 0 !important;
  }
  .navbar-toggler {
  }
  .menu_down_inner {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
  .menu {
    position: absolute;
    top: 40px;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .logo {
    flex: 0 0 28%;
    order: 2;
  }
  .menu_bar {
    flex: 0 0 100%;
    order: 1;
  }
  .menu_bar_up {
    height: 100%;
    border-bottom-left-radius: 0px;
    padding: 8px 16px 8px 16px;
  }
  .menu {
    margin-left: 0px;
    margin-top: -40px;
  }
}
@media only screen and (max-width: 519px) {
}

button.blue,
a.blue {
  font-weight: 600;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  align-items: center;
  background-image: linear-gradient(90deg, #233e7f 0%, #3f69ca 100%);
  border: 1px solid #3153a3;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}
button.yellow,
a.yellow {
  font-weight: 600;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 20px;
  color: #1a2f60;
  align-items: center;
  background-color: #fab84c;
  border: 1px solid #fab84c;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}
a.blue,
a.yellow {
  text-decoration: none;
}
.list-1{
  @media (max-width: $mobile-width) {
    justify-content: flex-end !important;
    margin-top: 1rem !important;
  }
}
ul.list-1,
ul.list-2,
ul.list-3,
ul.list-4,

.social-list {
  list-style-type: none;
  display: flex;
  width: 100%;
}
ul.list-1 > li {
  display: flex;
}
ul.list-1 span.lang-switch {
  display: flex;
  column-gap: 4px;
  align-items: center;
}
ul.list-1 span.lang-switch > a {
  text-decoration: none;
  padding: 8px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}
ul.list-1 span.lang-switch > a.active {
  font-weight: bold;
  text-decoration: underline;
}
ul.list-1 span.lang-switch > img {
  height: 30px;
}
ul.list-1 span.avatar span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
ul.list-1 span.avatar {
  display: flex;
  column-gap: 8px;
  align-items: center;
}
ul.list-1 span.avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #6f8ed8;
  background-color: #dfe6f6;
}
ul.list-1 span.notification > a {
  text-decoration: none;
  font-weight: 600;
  padding: 8px;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
}
ul.list-2 {
  justify-content: space-around;
}
ul.list-2 > li a {
  text-decoration: none;
  padding: 8px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  border: 2px solid transparent;
  cursor: pointer;
}
ul.list-2 > li a.active,
ul.list-2 > li a:hover {
  border: 2px solid #05944f;
  border-radius: 5px;
}
ul.list-2 > li img {
  width: 20px;
}
ul.list-2 > li span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3a3a47;
}
ul.list-2 > li a.active > span,
ul.list-2 > li a:hover > span {
  font-weight: bold;
  color: #1b629d;
}
.container-1 {
  padding: 0.01em 0px;
  background-color: #dfe6f6;
  margin-bottom: 40px;
}
.container-1 > h2 {
  width: 90%;
  margin: 10px auto;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
}

#menuc #listc {
  max-height: 0px;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  top: 50px;
  position: absolute;
  list-style: none;
  padding: 0px;
  right: 15px;
  background-color: white;
}

#menuc:hover #listc {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

#menuc #listc li * {
  font-weight: 600;
  color: #3a3a47;
}

#menuc #listc li * :hover {
  font-weight: bold;
  color: #1b629d;
}

#menuc > #listc > ul > li > button:hover {
  border: 2px solid #05944f;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .headerm > div {
    flex-direction: column;
  }
  .headerm > div > div {
    width: 100%;
  }
  .headerm > div > div:first-child {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .headerm > div > div:last-child > div:first-child {
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .headerm > div > div:last-child > div:first-child p {
    text-align: center;
  }
  .section-1 {
    row-gap: 20px;
  }
  .section-1 > aside {
    grid-area: 1 / 1 / span 1 / span 4;
  }
  .section-1 > main {
    grid-area: 2 / 1 / span 1 / span 4;
  }
  .flex-1 {
    flex-direction: column;
    row-gap: 16px;
  }
  .flex-1 > div {
    width: 100%;
  }
  ul.list-1 {
    justify-content: space-between;
  }
  ul.list-2 {
    flex-direction: column;
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 100px;
  }
  ul.list-2 > li {

    padding-top: 20px;
  }
  ul.list-4 > li > .main .row-1 {
    flex-direction: column;
    row-gap: 20px;
  }
  ul.list-4 > li > .main .row-2 {
    align-items: center;
  }
  ul.list-4 > li > .foot ul.grid {
    grid-template-columns: repeat(1, 1fr);
  }
  ul.list-5 {
    align-items: center;
  }
  .grid-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  ul.pagination {
    column-gap: 5px;
  }
  .modal .modal-content {
    width: 90%;
  }
  footer .container {
    flex-direction: column;
    row-gap: 30px;
  }
  footer .container > div {
    align-items: center;
  }
  footer .text {
    text-align: center;
  }
}
@media (min-width: 769px) {
  .headerm > div {
    height: 100px;
  }
  .headerm > div > div:first-child {
    width: 20%;
  }
  .headerm > div > div:last-child {
    width: 80%;
  }
  .headerm > div > div:last-child > div:first-child {
    border-radius: 0px 0px 0px 60px;
    align-items: center;
    justify-content: space-around;
  }
  .headerm > div > div:last-child > div:first-child > div {
    width: 45%;
  }
  .headerm > div > div:last-child > div:first-child,
  .headerm > div > div:last-child > div:last-child {
    height: 50px;
  }
  ul.list-1 {
    justify-content: flex-end;
    gap: 16px;
  }
  .section-1 {
    column-gap: 40px;
  }
  .section-1 > aside {
    grid-column: 1 / span 1;
  }
  .section-1 > main {
    grid-column: 2 / span 3;
  }
  .input-box.custom-width > input {
    width: 373px;
  }
  ul.list-4 > li > .main .row-1,
  ul.list-4 > li > .main .row-2 {
    align-items: center;
  }
  ul.list-4 > li > .main .row-1 > div:first-child {
    flex-basis: 60%;
  }
  ul.list-4 > li > .main .row-1 > div:last-child {
    flex-basis: 40%;
  }
  ul.list-4 > li > .foot ul.grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-1 {
    grid-template-columns: repeat(3, 1fr);
  }
  .grid-1 > div:nth-of-type(1),
  .grid-1 > div:nth-of-type(2),
  .grid-1 > div:nth-of-type(7),
  .grid-1 > div:nth-of-type(8),
  .grid-1 > div:nth-of-type(9) {
    grid-column: 1 / span 3;
  }
  .grid-1 > div:nth-of-type(5) {
    grid-column: 1 / span 1;
  }
  .grid-1 > div:nth-of-type(6) {
    grid-column: 1 / span 2;
  }
  ul.pagination {
    column-gap: 10px;
  }
  footer .container > div:last-child {
    align-items: flex-end;
  }
}

.li_hoover {
  &:hover {
    background-color: #233e7f !important;
  }

  p{

    &:hover{
      color: #ffffff !important;
    }
  }
}

.menuMovil {
  display: block !important;
  position: absolute;
  width: 99vw;
  left: 0;
}

.cuentaMenu {
  display: none;

  @media (max-width: $mobile-width) {
    display: block;
  }
}
