@import "atoms/variables";@import "functions";
.bigSearchBox {
  background: $gradient-blue-primary;
  display: flex;
  height: 0;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: height 0.2s ease-in-out;
  width: 100%;
  z-index: 2;

  @media (min-width: $mobile-width) {
    align-items: center;
  }

  &.opened {
    height: 100vh;
  }

  a,
  p,
  svg {
    color: $white;
  }

  .closeButton {
    display: none;
    position: absolute;
    right: rem(16px);
    top: rem(16px);

    @media (min-width: $mobile-width) {
      display: block;
    }

    @media (min-width: rem(1085px)) {
      right: rem(50px);
      top: rem(50px);
    }
  }

  .searchAndResults {
    max-width: rem(995px);
    padding: rem(16px);
    width: 100%;

    @media (min-width: $mobile-width) {
      min-height: 75vh;
    }

    .searchInputContainer {
      margin-bottom: rem(24px);
      position: relative;

      @media (min-width: $mobile-width) {
        margin-bottom: rem(40px);
      }

      button,
      & > svg {
        position: absolute;
      }

      button {
        @media (min-width: $mobile-width) {
          display: none !important;
        }

        svg {
          transform: rotateY(180deg);
        }
      }

      & > svg {
        display: none !important;

        @media (min-width: $mobile-width) {
          display: inline-block !important;
          right: 0;
        }
      }

      & > label {
        font-size: 0;
      }

      input {
        background: transparent;
        border: none;
        border-bottom: rem(2px) solid $white;
        border-radius: 0;
        caret-color: $semantic-warning;
        color: $white;
        font-weight: 500;
        padding: 0;
        padding-bottom: rem(14px);
        padding-left: rem(30px);

        @media (min-width: $mobile-width) {
          font-size: rem(37px);
          padding-bottom: rem(20px);
          padding-left: 0;
          padding-right: rem(60px);
        }

        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
    }

    .resultsAndPreview {
      display: flex;

      .results,
      .preview {
        & > a {
          align-items: center;
          color: $white;
          display: flex;
          justify-content: flex-end;
          text-decoration: none;

          &:focus,
          &:hover {
            text-decoration: underline;
          }

          svg {
            margin-left: rem(11px);
          }
        }
      }

      .results {
        width: 100%;

        @media (min-width: $mobile-width) {
          margin-right: rem(30px);
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            border-bottom: 1px solid $blue-primary-300;
            margin-bottom: rem(16px);
            padding-bottom: rem(16px);

            &:focus,
            &:hover {
              background: rgba(255, 255, 255, 0.2);
            }

            a {
              display: flex;
              text-decoration: none;

              .imageContainer {
                border-radius: rem(5px);
                flex-shrink: 0;
                height: rem(65px);
                margin-right: rem(16px);
                overflow: hidden;
                position: relative;
                width: rem(100px);

                @media (min-width: $mobile-width) {
                  height: rem(85px);
                  margin-right: rem(32px);
                  width: rem(175px);
                }
              }

              .content {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                p {
                  &:first-child {
                    margin-bottom: rem(8px);

                    @media (min-width: $mobile-width) {
                      font-size: rem(20px);
                    }
                  }

                  &:last-child {
                    font-size: rem(15px);

                    @media (min-width: $mobile-width) {
                      font-size: rem(16px);
                    }
                  }
                }
              }
            }
          }
        }

        & > a {
          margin-top: rem(26px);

          @media (min-width: $mobile-width) {
            justify-content: flex-start;
          }
        }
      }

      .preview {
        display: none;

        @media (min-width: $mobile-width) {
          display: flex;
          flex-direction: column;
        }

        .imageContainer {
          border-radius: rem(10px);
          height: rem(235px);
          margin-bottom: rem(24px);
          overflow: hidden;
          position: relative;
          width: rem(380px);
        }

        .content {
          p:first-child {
            font-size: rem(20px);
            margin-bottom: rem(8px);
          }

          p:last-child {
            font-size: rem(18px);
          }
        }

        & > a {
          margin-top: rem(32px);
        }
      }
    }

    .notFound {
      & > p {
        @media (min-width: $mobile-width) {
          font-size: rem(20px);
        }
      }

      & > p:first-child {
        margin-bottom: rem(20px);

        @media (min-width: $mobile-width) {
          margin-bottom: rem(40px);
        }

        span {
          font-weight: 600;
        }
      }

      & > p:nth-child(2) {
        margin-bottom: rem(20px);

        @media (min-width: $mobile-width) {
          margin-bottom: rem(27px);
        }
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          align-items: center;
          margin-bottom: rem(23px);

          @media (min-width: $mobile-width) {
            margin-bottom: rem(16px);
          }

          a {
            align-items: center;
            display: flex;
            text-decoration: none;

            svg {
              margin-right: rem(15px);
            }
          }
        }
      }
    }
  }
}
