@import "atoms/variables";@import "functions";
.footer {
  margin-bottom: rem(8px);
  margin-top: rem(23px);
  padding: 0 rem(16px);
  text-align: center;

  @media (min-width: $mobile-width) {
    margin-bottom: rem(21px);
  }

  span {
    color: $gray-scale-500;
    font-size: rem(12px);
    font-weight: 500;

    @media (min-width: $mobile-width) {
      color: $grey-dark-1;
      font-size: rem(14px);
      font-weight: 400;
    }
  }
}
