@import "atoms/variables";@import "functions";
.mobileMenu {
  background-color: $white;
  padding: rem(15px);
  position: fixed;
  right: rem(-310px);
  transition: right 0.4s ease-in-out;
  width: rem(310px);
  z-index: 1;

  @media (min-width: $mobile-width) {
    display: none;
  }

  &.opened {
    right: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      a,
      button {
        align-items: center;
        background-color: transparent;
        border: none;
        display: flex;
        padding: rem(12px);
        text-decoration: none;
        width: 100%;

        svg {
          color: $gray-scale-600;
          margin-right: rem(7px);
        }
      }

      a p,
      button,
      p {
        color: $gray-scale-600;
      }
    }
  }
}
