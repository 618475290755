@import "atoms/variables";@import "functions";
.passwordStrengthContainer {
  margin-top: rem(12px);

  .passwordStrength {
    div:nth-child(odd) {
      border-radius: 3px;
      height: 5px !important;
    }
  }

  .passwordChecker {
    display: flex;

    span {
      background-color: $gray-scale-300;
      border-radius: rem(3px);
      flex-grow: 1;
      height: rem(5px);

      &:nth-child(2) {
        margin: 0 rem(7px);
      }
    }
  }

  .weak {
    span:first-child {
      background-color: $semantic-error;
    }
  }

  .okay {
    span:first-child,
    span:nth-child(2) {
      background-color: $semantic-warning;
    }
  }

  .strong {
    span {
      background-color: $semantic-success;
    }
  }

  .passwordMessage {
    margin: rem(6px) 0;

    &:before {
      background-size: cover;
      content: '';
      display: inline-block;
      height: rem(12px);
      margin-right: rem(5px);
      width: rem(12px);
    }

    &.weak {
      color: $semantic-error !important;

      &:before {
        background-image: url('/icons/error.svg');
      }
    }

    &.okay {
      color: $semantic-warning !important;

      &:before {
        background-image: url('/icons/warning.svg');
      }
    }

    &.strong {
      color: $semantic-success !important;

      &:before {
        background-image: url('/icons/check.svg');
      }
    }
  }

  ul {
    margin: rem(15px) 0 0;
    padding-left: rem(20px);

    li {
      color: $gray-scale-800;
      font-size: rem(15px);
    }
  }
}
