@import "atoms/variables";@import "functions";
.userImageContainer {
  
  overflow: hidden;

  &.inHeader {
    border-radius: 50%;
    height: rem(32px);
    width: rem(32px);
  }

  &.inProfile {
    height: rem(110px);
    width: rem(110px);
    border-radius: 10px;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
