@import "atoms/variables";@import "functions";
.headerContainer {
  position: relative;
  z-index: 2;

  

  .mainHeader {
    background-color: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    display: flex;
    height: rem(60px);
    justify-content: space-between;
    padding-left: rem(16px);
    padding-right: rem(16px);
   



    @media (min-width: $mobile-width) {
      height: rem(105px);
      padding: 0;
    }
    .openSearchBox,
    .openMobileMenu {
      align-items: center;
      border: none;
      border-radius: rem(5px);
      display: flex;
      height: rem(48px);
      justify-content: center;
      padding: 0;
      width: rem(48px);

      @media (min-width: $mobile-width) {
        display: none;
      }
    }
    .switchLanguage{
      align-items: center;
      border: none;
      display: flex;
      margin-top: rem(5px);
      @media (min-width: $mobile-width) {
        display: none;
      }

    }

    @media (max-width: $mobile-width) {
    .openMobileMenu { order: 3; }
    .switchLanguage { order: 2; }
} 
   

    .openSearchBox {
      background-color: $primary-blue;

      svg {
        color: $semantic-warning;
      }
    }
    .openMobileMenu {
      background-color: $semantic-warning;
      margin-left: rem(5px);
      margin-top: rem(9px);

      svg {
        color: $primary-blue;
      }
    }

    .logoContainer {
      align-items: center;
      display: flex;

      @media (min-width: $mobile-width) {
        padding: 0 rem(27px);
      }

      @media (max-width: $mobile-width) {

      }

      a {
        display: block;
        height: rem(37px);
        position: relative;
        width: rem(97px);

        @media (min-width: $mobile-width) {
          height: rem(81px);
          width: rem(212px);
        }
      }
    }

    .logoContainerCop16 {
      align-items: center;
      display: flex;

      @media (min-width: $mobile-width) {
        padding: 0 rem(27px);
      }

      @media (max-width: $mobile-width) {

      }

      a {
        display: block;
        height: rem(37px);
        position: relative;
        width: rem(97px);

        @media (min-width: $mobile-width) {
          height: rem(81px);
          width: rem(212px);
        }
      }
    }



    .logoContainerMenu {
      align-items: center;
      display: flex;

      @media (min-width: $mobile-width) {
        visibility: hidden;
        height: 0;
        width: 0;
      }

      a {
        display: block;
        height: rem(37px);
        position: relative;
        width: rem(97px);

        @media (min-width: $mobile-width) {
          height: rem(81px);
          width: rem(212px);
        }
      }
    }

    .headerContent {
      align-items: center;
      display: flex;
      justify-content: space-between;

      @media (min-width: $mobile-width) {
        align-items: initial;
        flex-direction: column;
        flex-grow: 1;
      }

      & > div:first-child {
        align-items: center;
        display: flex;

        @media (min-width: $mobile-width) {
          background: $gradient-blue-primary-reverse;
          height: rem(48px);
          flex-shrink: 0;
          justify-content: space-between;
          padding: 0 rem(16px);
          border-radius: 0px 0px 0px 55px;
        }

        & > p {
          color: $blue-primary-50;
          display: none;
          font-size: rem(18px);

          @media (min-width: $mobile-width) {
            display: block;
          }
        }

        .options {
          display: flex;
          & > div {
            @media (min-width: $mobile-width) {
              margin-right: rem(48px);
            }
          }

          .registerLogin {
            display: flex;

            a {
              display: none;

              @media (min-width: $mobile-width) {
                align-items: center;
                background-color: $yellow-400;
                color: $blue-primary-800;
                display: flex;
                font-size: rem(15px);
                font-weight: 500;
                margin-right: rem(8px);
              }
            }

            button {
              display: none;

              @media (min-width: $mobile-width) {
                background: $gray-scale-100;
                color: $blue-primary-700;
                display: block;
                font-size: rem(15px);
                font-weight: 500;
              }
            }
          }

          .profileLink {
            text-decoration: none;
            background: none ;
            border: none;
            padding: 0;
            display: flex;
            align-items: center;

            @media (max-width: $mobile-width) {
              display: none;
            }

            p {
              color: $white;
              font-weight: 600;
              margin-right: rem(6px);
            }
          }

          .openSearchBox,
          .openShop,
          .openMobileMenu {
            align-items: center;
            border: none;
            border-radius: rem(5px);
            display: flex;
            height: rem(48px);
            justify-content: center;
            padding: 0;
            width: rem(48px);

            @media (min-width: $mobile-width) {
              display: none;
            }
          }
          .openShop {
            background-color: $primary-blue;
            svg {
              color: $semantic-warning;
            }
          }
          .openSearchBox {
            background-color: $primary-blue;
            padding-left: 3%;
            svg {
              color: $semantic-warning;
            }
          }

          .openMobileMenu {
            background-color: $semantic-warning;
            margin-left: rem(24px);

            svg {
              color: $primary-blue;
            }
          }
        }
      }

      & > div:last-child {
        display: none;

        @media (min-width: $mobile-width) {
          align-items: center;
          display: flex;
          flex-grow: 1;
        }

        ul {
          display: flex;
          flex-grow: 1;
          justify-content: space-around;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            display: flex;

            &.active {
              border: rem(2px) solid $semantic-success;
              border-radius: rem(5px);
            }

            a,
            button {
              align-items: center;
              background-color: transparent;
              border: none;
              border-radius: rem(5px);
              display: flex;
              padding: rem(6px);
              text-decoration: none;

              &.active {
                border: rem(2px) solid $semantic-success;

                p {
                  color: $semantic-links;
                  font-weight: 700;
                }
              }

              svg {
                color: $semantic-links;
                margin-right: rem(9px);
              }

              p {
                color: $gray-scale-600;
                font-size: rem(15px);
                font-weight: 500;
              }
            }
          }
        }

        .searchButton {
          align-items: center;
          background-color: transparent;
          border: none;
          color: $primary-blue;
          display: flex;
          font-size: rem(15px);
          font-weight: 500;
          margin: 0 rem(70px) 0 rem(10px);
          border-left: 1px solid #c4c4c4;
          padding: 17px;

          svg {
            color: $primary-blue;
            margin-left: rem(8px);
          }
        }
      }
    }
  }

  .logoutPopup {
    p {
      margin-bottom: rem(20px);
    }

    .options {
      display: flex;
      justify-content: center;

      button {
        margin: 0 rem(5px);
        width: rem(50px);
      }
    }
  }
}

.menu {
  margin-left: -102px;
  border: 1px solid #d3d4dd;
  background-color: white;
  position: absolute;
  top: 40px;
  text-align: center;
}

.menu li {
  list-style: none;
}

.menu ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

.menu a {
  color: #3a3a47 !important;
  font-size: 16px;
}
.menu a img {
  color: #3a3a47 !important;
  padding-right: 4px;
}

