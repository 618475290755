@import "atoms/variables";@import "functions";
.footer {
    .associateLogos {
      align-items: center;
      background-color: $gray-scale-100;
      display: flex;
      justify-content: space-around;
      padding: rem(39px) 0;
  
      @media (max-width: 930px) {
        display: none;
      }
    }
  
    .middleSection {
      background: $white;
      display: flex;
      justify-content: space-around;
      padding-bottom: rem(25px);
      padding-top: rem(25px);
  
      @media (max-width: $mobile-width) {
        align-items: center;
        background-color: $blue-primary-800;
        flex-direction: column;
        padding-top: rem(25px);
      }
  
      .logoContainer {
          margin-top: 30px;
        .logo {
            height: 100px;
        }
      }
  
      .links {
        display: inline-flex;
        justify-content: space-around;
        @media (max-width: $mobile-width) {
          align-items: center;
          display: flex;
          flex-direction: column;
        }
  
        div > ul {
          padding: 0;
  
          li {
            list-style: none;
          }
        }
  
        & > div > ul:first-of-type {
          margin: 0 0 rem(32px);
  
          li {
            @media (max-width: $mobile-width) {
              text-align: center;
            }
  
            &:not(:last-child) {
              margin-bottom: rem(12px);
  
              @media (max-width: $mobile-width) {
                margin-bottom: rem(16px);
              }
            }
  
            a {
              color: $semantic-links;
              text-decoration: none;
              font-weight: 600;
  
              @media (max-width: $mobile-width) {
                color: $white;
              }
            }
          }
        }
        
        .firstDiv {
          
        }
        
      }
  
      
  
      .appLinks {
        max-width: rem(150px);
        width: 100%;
        padding: 0;
        & > div {
            margin-left: 38px;
            // margin-top: 55px;
        }
        p {
            
            width: 94px;
            height: 20px;

            /* Paragraph / mid / 600 */

            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height */

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.0025em;

            /* Gray Scale / 900 */

            color: #24252E;


            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
  
            @media (max-width: $mobile-width) {
              color: $white;
            }
        }
        li {
          list-style: none;
        }
        & >  ul:last-of-type {
            display: flex;
            // margin: rem(42px) 0 0;
    
            @media (max-width: $mobile-width) {
              justify-content: space-around;
              margin-bottom: rem(35px);
              max-width: rem(360px);
              width: 100%;
            }
    
            li a {
              font-size: 0;
            }
    
            .facebook a {
              color: $facebook-color;
            }
    
            .youtube a {
              color: $youtube-color;
            }
    
            .instagram a {
              color: $instagram-color;
            }
    
            .twitter a {
              color: $twitter-color;
            }
    
            .facebook a,
            .youtube a,
            .instagram a,
            .twitter a {
              @media (max-width: $mobile-width) {
                color: $white;
              }
            }
    
            li:not(:last-child) {
              margin-right: rem(20px);
    
              @media (max-width: $mobile-width) {
                margin: 0;
              }
            }
          
        }
      }
    }
  
    .footerPreEnd {
        background:#FAB84C80;
        padding: rem(8px) 0;
    
        p {
          color: $gray-scale-900;
          font-size: rem(14px);
          margin: 0;
          text-align: center;
        }
      }
    .footerEnd {
      background: $gradient-green-secondary;
      padding: rem(8px) 0;
  
      p {
        color: $gray-scale-900;
        font-size: rem(14px);
        margin: 0;
        text-align: center;
      }
    }
  }
  